<template>
  <div v-loading="prefilling" class="eden-page-form">
    <el-form :model="form" label-position="top" ref="form">
      <eden-form-section
        title="Cleaning Type"
        subtitle="Basic information about this particular service type"
      >
        <template slot="form-fields">
          <el-row type="flex">
            <el-col :md="24">
              <el-form-item label="Service">
                <el-input type="text" v-model="form.service" disabled />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :md="24">
              <el-form-item
                label="Service type name"
                prop="service_type_name"
                :rules="validateField()"
              >
                <el-input type="text" v-model="form.service_type_name" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :md="24">
              <el-form-item
                label="Minimum price"
                prop="minimum_price"
                :rules="validateField()"
              >
              <el-input
                    placeholder="Enter amount"
                    v-model="form.minimum_price"
                    v-number
                  >
                    <template slot="append">{{ formatCurrency() }}</template>
                  </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section
        title="Cleaning Pricing"
        subtitle="Information about standard pricing"
      >
        <template slot="form-fields">
          <div
            v-for="(area, index) in form.cleaning_areas"
            :key="index"
            class="area fadeIn"
          >
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Area"
                  :prop="'cleaning_areas.' + index + '.slug'"
                  :rules="validateField()"
                >
                  <el-select
                    v-model="area.slug"
                    filterable
                    placeholder="Select area"
                  >
                    <el-option
                      v-for="(item, index) in areas"
                      :key="index"
                      :label="item.name"
                      :value="item.slug"
                    />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="24">
                <el-form-item
                  label="Price per unit"
                  :prop="'cleaning_areas.' + index + '.price'"
                  :rules="validateField()"
                >
                  <el-input
                    placeholder="Enter amount"
                    v-model="area.price"
                    v-number
                  >
                    <template slot="append">{{ formatCurrency() }}</template>
                  </el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </div>
          <el-row type="flex">
            <el-col :span="24" class="is-flex is-justify-center">
              <div class="section-add" @click="addArea">
                <span><i class="el-icon-plus"></i></span>
                <p>Add an area</p>
              </div>
            </el-col>
          </el-row>
        </template>
      </eden-form-section>
      <eden-form-section title="" subtitle="">
        <template slot="form-fields">
          <div class="eden-page-form__actions">
            <el-button plain @click="cancel">Cancel</el-button>
            <el-button
              v-if="action === 'add'"
              type="primary"
              :loading="adding"
              @click="add"
              >Add cleaning type</el-button
            >
            <el-button v-else type="primary" :loading="updating" @click="update"
              >Save changes</el-button
            >
          </div>
        </template>
      </eden-form-section>
    </el-form>
  </div>
</template>

<script>
import service from "@/requests/services/service";
import cleaning from "@/requests/services/cleaning";

export default {
  name: "CleaningForm",
  props: {
    action: {
      type: String,
      default: "add",
    },
    serviceTypeId: {
      type: [String, Number],
      default: "",
    },
    serviceType: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      prefilling: false,
      form: {
        service: "Cleaning",
        service_type_name: "",
        minimum_price: "",
        service_type_category: "single",
        cleaning_areas: [
          {
            slug: "",
            price: "",
          },
        ],
      },
      adding: false,
      updating: false,
      areas: [],
      showCleaningAreaAdd: false,
    };
  },
  created() {
    this.getAreas();
    if (this.action === "edit") {
      this.prefilling = true;
      this.form = this.serviceType;
      setTimeout(() => {
        this.prefilling = false;
      }, 1000);
    }
  },
  methods: {
    getAreas() {
      cleaning.areas().then((response) => {
        if (response.data.status) {
          this.areas = this.sortArray({
            data: response.data.data,
            property: "name",
          });
        }
      });
    },
    addArea() {
      this.form.cleaning_areas.push({
        slug: "",
        price: "",
      });
    },
    updateAreas(areas) {
      this.areas = areas;
    },
    cancel() {
      this.$router.push({ name: "services.service", service: "meal" });
    },
    add() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.adding = true;
        service
          .serviceTypeAdd(this.form)
          .then((response) => {
            const { status, message } = response.data;
            if (status) {
              this.success(message);
            } else {
              this.adding = false;
            }
          })
          .catch((error) => {
            this.adding = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
    update() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        this.updating = true;
        service
          .serviceTypeUpdate(this.serviceTypeId, this.form)
          .then((response) => {
            const { status, message } = response.data;
            if (status) {
              this.success(message);
            } else {
              this.updating = false;
            }
          })
          .catch((error) => {
            this.updating = false;
            const errorMessage = error.response.data;
            if (errorMessage.errors) {
              const errorKeys = Object.keys(errorMessage.errors);
              this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
            } else {
              this.$message.error(errorMessage.message);
            }
          });
        return true;
      });
    },
    success(message) {
      this.adding = false;
      this.updating = false;
      this.$message.success(message);
      this.$router.push({
        name: "services.service",
        params: { service: "cleaning" },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.area {
  background: #004d2908;
  padding: 20px 10px;
  border-radius: 4px;
  margin-bottom: 15px;

  .label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
  }

  .area-remove {
    height: 16px;
    width: 16px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #21312a10;
    cursor: pointer;

    i {
      color: #21312a;
      font-size: 0.75rem;
      font-weight: 600;
    }
  }
}
</style>
